@keyframes moveBackground {
    0% { background-position: 0 0; }
    100% { background-position: 100% 100%; }
}

.courses-page header {
    background: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../utils/images/subject.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    animation: moveBackground 10s linear infinite;
}

.courses-page .card-img-overlay {
    background-color: rgba(5, 10, 35, 0.7);
}

.courses-page .card:hover {
    cursor: pointer;
}