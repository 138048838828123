@keyframes moveBackground {
    0% { background-position: 0 0; }
    100% { background-position: 100% 100%; }
}

.contact-page header {
    background: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../utils/images/contact.avif);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    animation: moveBackground 10s linear infinite;
}

#contact-form {
    width: 50%;
}

#contact-form .form-control:focus,
#contact-form .form-select:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, .25);
}

@media only screen and (max-width: 992px) {
    #contact-form {
        width: 75%;
    }
}

@media only screen and (max-width: 576px) {
    #contact-form {
        width: 100%;
    }
}