/* Background image for testimonials header */
/* .testimonials-container header {
  background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../utils/images/about-page-header.jpg);
} */
/* Background image for testimonials header */
/* .testimonials-container header {
  background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url('../../utils/images/teacher.avif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  padding: 2rem 0;
  text-align: center;
} */

@keyframes moveBackground {
  0% { background-position: 0 0; }
  100% { background-position: 100% 100%; }
}

.testimonials-container header {
  background: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../utils/images/teacher.avif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: moveBackground 10s linear infinite;
}


/* Styling for the testimonials section */
.testimonials-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  color: #333; /* Text color */
}

.testimonials-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  padding: 0 1rem;
}

.testimonial-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(247, 6, 6, 0.9);
  padding: 1.5rem;
  transition: transform 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}

.testimonial-card .card-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.testimonial-card img.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 1rem;
}

.testimonial-card .name {
  font-size: 1.25rem;
  font-weight: bold;
  color: hsl(0, 89%, 48%); /* Name color */
}

.testimonial-card .position {
  font-size: 0.875rem;
  color: hsl(0, 84%, 12%); /* Position color */
}

.testimonial-card .testimonial {
  font-size: 1rem;
  color: hsl(313, 75%, 43%); /* Testimonial color */
}

@media (min-width: 768px) {
  .testimonials-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .testimonials-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
